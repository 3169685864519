body.popup-active {
  overflow: hidden;
}

#popup-product {
  position: fixed;
  inset: 0;
  visibility: hidden;
  z-index: -1;

  body.popup-active & {
	visibility: visible;
	display: flex;
	z-index: 20;
  }

  align-items: center;
  justify-content: center;
  flex-direction: column;

  .bg {
	inset: 0;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1;
	backdrop-filter: blur(3px);
	opacity: 0;
	transition: opacity 0.5s ease;

	body.popup-active & {
	  opacity: 1;
	}

  }

  .actions {
	position: relative;
	z-index: 2;
	margin-bottom: 5px;
	width: 300px;
	text-align: right;

	button {
	  color: #FFF;
	  background: transparent;
	  border: none;

	  opacity: 0;
	  transition: opacity 0.5s ease 0.3s;

	  body.popup-active & {
		opacity: 1;
	  }


	}
  }

  .inner {
	position: relative;
	z-index: 2;
	background-color: #FFF;
	width: 300px;
	padding: 30px;
	border-radius: 60px 0;

	.in {
	  max-height: calc(100vh - 150px);
	  overflow: auto;

	}

	.icons {

	  opacity: 0;
	  transition: opacity 0.5s ease 0.25s;

	  body.popup-active & {
		opacity: 1;
	  }


	  .icon {
		display: none;
		padding-bottom: 25px;
		justify-content: center;

		&.active {
		  display: flex;
		}

		svg {
		  width: 80px;
		  height: auto

		}

	  }
	}

	article {
	  display: none;

	  &.active {
		display: block;
	  }

	  h3 {
		border-left: 1px solid $primary;
		font-size: 1.1rem;
		line-height: 1.1rem;
		font-weight: bold;
		margin: 0 0 25px 0;
		padding: 0 0 0 9px;

		opacity: 0;
		transition: opacity 0.5s ease 0.25s;

		body.popup-active & {
		  opacity: 1;
		}

	  }

	  p {

		opacity: 0;
		transition: opacity 0.5s ease 0.25s;

		body.popup-active & {
		  opacity: 1;
		}

	  }
	}
  }
}