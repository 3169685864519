body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

img {
  width: 100%;
}

p {
  font-size: .9rem;
}

main {
  padding-top: 50px;
}

.swiper-button-prev, .swiper-button-next {
  &:after {
	display: none;
  }

  svg {
	stroke: #FFF;
	width: 100%;
  }
}

.swiper-button-prev {
  left: 4px;
}

.swiper-button-next {
  right: 4px;
}

.titre-2 {
  color: $primary;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 25px;
  @include media-breakpoint-up(md) {
	font-size: 1.8rem;
  }

  &.mt-large {
	@include media-breakpoint-up(md) {
	  margin-bottom: 50px;
	}
	@include media-breakpoint-up(lg) {
	  margin-bottom: 75px;
	}
  }
}

.section-padding-top {
  padding-top: 25px;
  @include media-breakpoint-up(md) {
	padding-top: 50px;
  }
  @include media-breakpoint-up(lg) {
	padding-top: 75px;
  }
}

.section-padding-bottom {
  padding-bottom: 25px;
  @include media-breakpoint-up(md) {
	padding-bottom: 50px;
  }
  @include media-breakpoint-up(lg) {
	padding-bottom: 75px;
  }
}

.text-medium {
  font-weight: 500;
}

#btn-audit {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 0;
  left: auto;
  background-color: $primary-dark;
  color: #FFF;
  border: none;
  padding: 10px 10px 10px 15px;
  border-radius: 20px 0 0 20px;
  font-weight: bold;
  z-index: 10;
}

.overflow-hidden {
  overflow: hidden;
}