#intro {
  #swiper-intro {
	width: 100%;
	height: 66.6vw;
	max-height: 600px;
	@include media-breakpoint-up(md) {
	  height: 70vw;
	}

	.swiper-slide {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;

	  h1, h2 {
		color: #FFF;
		font-weight: bold;
		text-shadow: 0 3px 6px #3d3d3d;
		width: 80vw;
		text-align: center;
		font-size: 1.3rem;

		@include media-breakpoint-up(md) {
		  font-size: 3rem;
		}

	  }

	  p {
		margin-top: 15px;
		width: 80vw;
		max-width: 500px;
		background: rgba(0, 0, 0, 0.44);
		padding: 10px;
		color: #FFF;
		text-align: center;
	  }

	  &.bg-team {
		background-image: url("../images/bg-team-500.webp");

		body.no-webp & {
		  background-image: url("../images/bg-team-500.jpg");
		}

		background-size: cover;
		background-position: center top;

		@include media-breakpoint-up(md) {
		  background-position: center 15%;
		  background-image: url("../images/bg-team-800.webp");
		  body.no-webp & {
			background-image: url("../images/bg-team-800.jpg");
		  }
		}
		@include media-breakpoint-up(lg) {
		  background-image: url("../images/bg-team.webp");
		  body.no-webp & {
			background-image: url("../images/bg-team.jpg");
		  }
		}
	  }

	  &.bg-expertise {
		background-image: url("../images/bg-expertise-500.webp");

		body.no-webp & {
		  background-image: url("../images/bg-expertise-500.jpg");
		}

		background-size: cover;
		background-position: center;

		@include media-breakpoint-up(md) {
		  background-image: url("../images/bg-expertise-800.webp");
		  body.no-webp & {
			background-image: url("../images/bg-expertise-800.jpg");
		  }
		}
		@include media-breakpoint-up(lg) {
		  background-image: url("../images/bg-expertise.webp");
		  body.no-webp & {
			background-image: url("../images/bg-expertise.jpg");
		  }
		}
	  }

	  &.bg-proximite {
		background-image: url("../images/bg-proximite-500.webp");

		body.no-webp & {
		  background-image: url("../images/bg-proximite-500.jpg");
		}

		background-size: cover;
		background-position: center;

		@include media-breakpoint-up(md) {
		  background-image: url("../images/bg-proximite-800.webp");
		  body.no-webp & {
			background-image: url("../images/bg-proximite-800.jpg");
		  }
		}
		@include media-breakpoint-up(lg) {
		  background-image: url("../images/bg-proximite.webp");
		  body.no-webp & {
			background-image: url("../images/bg-proximite.jpg");
		  }
		}
	  }

	  &.bg-reactivite {
		background-image: url("../images/bg-reactivite-500.webp");

		body.no-webp & {
		  background-image: url("../images/bg-reactivite-500.jpg");
		}

		background-size: cover;
		background-position: center;

		@include media-breakpoint-up(md) {
		  background-image: url("../images/bg-reactivite-800.webp");
		  body.no-webp & {
			background-image: url("../images/bg-reactivite-800.jpg");
		  }
		}
		@include media-breakpoint-up(lg) {
		  background-image: url("../images/bg-reactivite.webp");
		  body.no-webp & {
			background-image: url("../images/bg-reactivite.jpg");
		  }
		}
	  }

	  &.bg-competitive {
		background-image: url("../images/bg-competitive-500.webp");

		body.no-webp & {
		  background-image: url("../images/bg-competitive-500.jpg");
		}

		background-size: cover;
		background-position: center;

		@include media-breakpoint-up(md) {
		  background-image: url("../images/bg-competitive-800.webp");
		  body.no-webp & {
			background-image: url("../images/bg-competitive-800.jpg");
		  }
		}
		@include media-breakpoint-up(lg) {
		  background-image: url("../images/bg-competitive.webp");
		  body.no-webp & {
			background-image: url("../images/bg-competitive.jpg");
		  }
		}
	  }

	}
  }

  .list-products {
	ul {
	  margin: 0;
	  padding: 0;
	  list-style: none;
	  display: flex;
	  justify-content: space-between;
	  @include media-breakpoint-up(md) {
		justify-content: space-around;
	  }

	  li {
		a {
		  text-decoration: none;
		  font-weight: bold;
		  color: #000;
		  display: flex;
		  flex-direction: column;
		  text-align: center;

		  .icon {
			svg {
			  width: 50px;
			  @include media-breakpoint-up(md) {
				width: 120px;
			  }
			  @include media-breakpoint-up(lg) {
				width: 150px;
			  }
			}
		  }

		  .text {
			display: block;
			margin-top: 10px;
			font-size: .85rem;
			@include media-breakpoint-up(lg) {
			  font-size: 1.3rem;
			}
		  }
		}
	  }
	}
  }


}

.swipers {
  display: flex;
  position: relative;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
	width: 75vw;
  }
  @include media-breakpoint-up(lg) {
	width: 750px;
  }


  .swiper-button-prev {
	left: -25px;
	@include media-breakpoint-up(md) {
	  left: -40px;
	}
	@include media-breakpoint-up(lg) {
	  left: -100px;
	}
  }

  .swiper-button-next {
	right: -20px;
	@include media-breakpoint-up(md) {
	  right: -40px;
	}
	@include media-breakpoint-up(md) {
	  right: -90px;
	}
  }

  .swiper-button-prev, .swiper-button-next {
	svg {
	  stroke: $primary;
	  width: 100%;
	}
  }

  &:before {
	content: '';
	position: absolute;
	top: 15%;
	bottom: 15%;
	left: -1000px;
	right: -1000px;
	background: #F8F8F8;

	@include media-breakpoint-up(lg) {
	  left: -60px;
	  right: -50px;
	  border-right: 1px solid $primary;
	  border-left: 1px solid $primary;

	}

  }


  #swiper-activite-visuel {
	border-radius: 20% 0;
	width: 150px;
	height: 150px;
	flex: 0 0 150px;

	@include media-breakpoint-up(md) {
	  width: 250px;
	  height: 250px;
	  flex: 0 0 250px;
	}
	@include media-breakpoint-up(lg) {
	  width: 300px;
	  height: 300px;
	  flex: 0 0 300px;
	}


	.swiper-slide {

	  background-size: cover;
	  background-position: center;
	  transition: opacity 0.5s ease;

	  &.bg-industrie {
		background-image: url("../images/bg-industrie.webp");

		body.no-webp & {
		  background-image: url("../images/bg-industrie.jpg");
		}
	  }

	  &.bg-btp {
		background-image: url("../images/bg-btp.webp");

		body.no-webp & {
		  background-image: url("../images/bg-btp.jpg");
		}
	  }

	  &.bg-commerce {
		background-image: url("../images/bg-commerce.webp");

		body.no-webp & {
		  background-image: url("../images/bg-commerce.jpg");
		}
	  }

	  &.bg-restauration {
		background-image: url("../images/bg-restauration.webp");

		body.no-webp & {
		  background-image: url("../images/bg-restauration.jpg");
		}
	  }

	  &.bg-medical {
		background-image: url("../images/bg-medical.webp");

		body.no-webp & {
		  background-image: url("../images/bg-medical.jpg");
		}
	  }

	  &.bg-immobilier {
		background-image: url("../images/bg-immobilier.webp");

		body.no-webp & {
		  background-image: url("../images/bg-immobilier.jpg");
		}
	  }

	  &.bg-services-entreprises {
		background-image: url("../images/bg-services-entreprises.webp");

		body.no-webp & {
		  background-image: url("../images/bg-services-entreprises.jpg");
		}
	  }
	}
  }

  #swiper-activite-text {
	width: 100%;
	display: block;

	.swiper-slide {
	  display: flex;
	  align-items: center;
	  padding: 30px;
	  opacity: 0;
	  width: 100%;
	  transition: opacity 0.5s ease;

	  &.swiper-slide-active {
		opacity: 1;
	  }

	  h3 {
		text-align: left;
		width: 100%;
		color: $primary;
		font-weight: 300;
		font-size: 1.1rem;
		margin-bottom: 0;
		@include media-breakpoint-up(md) {
		  font-size: 1.8rem;

		}
	  }
	}
  }
}

#notre-agence {

  h2 {
	background: $primary-dark;
	padding: 15px;
	width: 100%;
	margin: 0;
	font-weight: bold;
	font-size: 1.6rem;
	text-align: center;
	color: #FFF;
	@include media-breakpoint-up(md) {
	  font-size: 2rem;
	}
  }

  h3 {
	border-left: 1px solid $primary;
	font-size: 1.1rem;
	line-height: 1.1rem;
	font-weight: bold;
	margin: 0 0 25px 0;
	padding: 0 0 0 9px;
	@include media-breakpoint-up(md) {
	  font-size: 1.3rem;
	  line-height: 1.3rem;
	}

  }

  article.presentation {
	@include media-breakpoint-up(md) {
	  display: flex;
	}

	.text {
	  margin-bottom: 25px;
	  @include media-breakpoint-up(md) {
		width: 50%;
	  }
	}

	.visuel {
	  @include media-breakpoint-up(md) {
		width: 50%;

		&.ornement-1 {
		  background-image: url("../images/NotreAgenceOrnement1.svg");
		  background-size: 75% auto;
		  background-position: center;
		  background-repeat: no-repeat;
		}
		&.ornement-2 {
		  background-image: url("../images/NotreAgenceOrnement2.svg");
		  background-size: 75% auto;
		  background-position: center;
		  background-repeat: no-repeat;
		}


	  }
	}
  }

  #section-team {


	position: relative;

	&:after {
	  position: absolute;
	  top: 20px;
	  left: 0;
	  right: 0;
	  bottom: 40px;
	  background: #F8F8F8;
	  content: "";
	  z-index: 1;
	  @include media-breakpoint-up(md) {
		bottom: 0;
	  }
	}

	.container {
	  position: relative;
	  z-index: 2;
	}

	.team-block {
	  max-width: 900px;
	  margin: 0 auto;
	  position: relative;
	  transform: translateY(-25px);
	  @include media-breakpoint-up(md) {
		transform: translateY(-50px);
	  }
	  @include media-breakpoint-up(lg) {
		transform: translateY(-75px);
	  }

	  h3 {
		margin-bottom: 50px;
	  }

	  .team {
		@include media-breakpoint-up(md) {
		  display: flex;
		  justify-content: space-between;
		}

		.team-person {
		  background: #FFF;
		  @include media-breakpoint-up(md) {
			width: 32%;
		  }


		  margin-bottom: 30px;
		  padding: 15px;
		  box-shadow: 0 3px 8px rgba(149, 143, 143, 0.73);

		  h4 {
			margin-top: 20px;
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 1.3rem;

			span {
			  display: block;
			  font-weight: 300;
			  font-size: 1rem;
			}

		  }

		  img {
			height: auto;
		  }

		}
	  }
	}

  }
}

#nos-solutions {
  h2 {
	background: $primary;
	padding: 15px;
	width: 100%;
	margin: 0;
	font-weight: bold;
	font-size: 1.6rem;
	text-align: center;
	color: #FFF;
	@include media-breakpoint-up(md) {
	  font-size: 2rem;
	}
  }

  .content-text {
	margin: 0 auto;
	max-width: 880px;

	ul {
	  padding-left: 15px;

	  li {
		list-style-image: url("../images/arrow.svg");
		padding-left: 5px;
		padding-bottom: 5px;
		font-size: 0.9rem;
	  }
	}
  }

  .list-product {
	margin: 0 auto;
	//max-width: 750px;
	@include media-breakpoint-up(md) {
	  display: flex;
	  justify-content: space-around;
	  margin-top: 50px;
	  margin-bottom: 50px;
	}

	article {
	  margin-bottom: 50px;
	  @include media-breakpoint-up(md) {
		width: 200px;
		margin-bottom: 0;
		flex: 0 0 200px;
	  }

	  &:last-child {
		margin-bottom: 0;
	  }

	  .entete {
		text-align: center;
		cursor: pointer;

		.icon {
		  svg {
			width: 150px;
			height: auto;
		  }
		}

		.text {
		  margin: 15px 0;
		  font-weight: bold;
		  font-size: 1.3rem;
		}

		.show_product {
		  .link {
			text-transform: uppercase;
			text-decoration: none;

			.btn-icon {
			  padding: 10px 0;
			  position: relative;
			  display: flex;
			  align-items: center;
			  justify-content: center;

			  &:after, &:before {
				content: '';
				height: 1px;
				width: 50px;
				background: $primary;
				position: relative;
				display: inline-block;
			  }

			  svg {
				margin: 0 10px;
				border: 1px solid $primary;
				border-radius: 3px;

				padding: 5px;
				width: 25px;
				height: auto;

				path {
				  transform-origin: center;
				}
			  }
			}
		  }
		}

		&.open-rotate {

		  .show_product {

			.link {

			  .btn-icon {

				svg {
				  path {
					transform: rotate(180deg);
				  }
				}
			  }
			}
		  }
		}

	  }


	  ul {
		margin: 0;
		padding: 0;
		list-style: none;
		max-height: 0;
		overflow: hidden;
		transition: all .5s;
		display: block;
		width: 100%;
		text-align: center;

		&.open {
		  max-height: 100vh;
		}


		li {
		  padding: 10px 0;

		  a {
			font-weight: bold;
			text-decoration: none;
			color: #000;

			&:hover {
			  color: $primary;
			}
		  }
		}
	  }
	}
  }

  .particulier {
	background: #F8F8F8;
	margin: 25px auto 0 auto;
	max-width: 400px;


	.inner {

	  margin: 0 auto;
	  border-top: 1px solid $primary;
	  border-bottom: 1px solid $primary;
	  padding: 15px;
	  text-align: center;

	  h3 {
		font-size: 1.1rem;
		font-weight: bold;
	  }

	  p {
		margin-bottom: 0;
	  }
	}
  }
}

#nous-soutenons {
  h2 {
	background: $primary-light;
	padding: 15px;
	width: 100%;
	margin: 0;
	font-weight: bold;
	font-size: 1.6rem;
	text-align: center;
	color: #FFF;
	@include media-breakpoint-up(md) {
	  font-size: 2rem;
	}
  }

  .support-list {

	display: flex;
	flex-wrap: wrap;

	a {
	  width: 50%;
	  height: 29.25vw;
	  position: relative;
	  display: flex;
	  text-decoration: none;

	  align-items: center;
	  justify-content: center;

	  background-size: cover;
	  background-position: center;
	  @include media-breakpoint-up(md) {
		width: 25%;
		height: 14.62vw;
	  }

	  &:before {
		position: absolute;
		inset: 0;
		content: '';
		background: #29388C;
		opacity: 0;
		z-index: 1;
		transition: opacity .5s ease;
	  }

	  &:hover::before {
		opacity: .5;

	  }

	  &.bg-es-vaulnaveys {
		background-position: top center;
		background-image: url("../images/partenaires/Partenaire_ESV.webp");

		body.no-webp & {
		  background-image: url("../images/partenaires/Partenaire_ESV.jpeg");
		}
	  }

	  &.bg-bruleurs-de-loup {
		background-image: url("../images/partenaires/Partenaire_BDL.webp");

		body.no-webp & {
		  background-image: url("../images/partenaires/Partenaire_BDL.jpeg");
		}
	  }

	  &.bg-martial-gym {
		background-image: url("../images/partenaires/Partenaire_MG.webp");

		body.no-webp & {
		  background-image: url("../images/partenaires/Partenaire_MG.jpeg");
		}
	  }

	  &.bg-al-echirolles {
		background-position: center left;
		background-image: url("../images/partenaires/Partenaire_ALE.webp");

		body.no-webp & {
		  background-image: url("../images/partenaires/Partenaire_ALE.jpeg");
		}
	  }


	  span {
		font-size: 1.2rem;
		padding-top: 12.5vw;
		color: #FFF;
		font-weight: bold;
		text-shadow: 0 3px 6px #000000B3;
		z-index: 2;
		position: relative;


		@include media-breakpoint-up(md) {
		  font-size: 1.1rem;
		  padding-top: 4rem;
		}
		@include media-breakpoint-up(lg) {
		  font-size: 1.3rem;
		  padding-top: 5rem;
		}
		@include media-breakpoint-up(xl) {
		  font-size: 1.4rem;
		  padding-top: 6rem;
		}


	  }
	}

  }
}

#contact {

  position: relative;

  &:after {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	bottom: 0;
	background: #F8F8F8;
	content: "";
	z-index: 1;
  }

  .container, .container-xl {
	position: relative;
	z-index: 2;
  }

  .inner {
	max-width: 750px;
	margin: 0 auto;
  }

  form {
	margin-bottom: 60px;

	.field {
	  margin: 5px 0;

	  label {
		display: block;
		font-weight: bold;
		font-size: 1rem;
	  }

	  input {

	  }


	}

	.captcha {
	  margin: 15px 0;
	}

	.form-check {
	  margin-top: 15px;
	  display: flex;
	  margin-left: 0;
	  font-size: 0.9rem;

	  input {
		margin-top: 6px;
		margin-right: 10px;
		flex: 0 0 16px;
	  }
	}

	.btn {
	  border-radius: 20px;
	  font-weight: bold;
	}
  }

  .sending-contact, .error-contact, .success-contact {
	display: none;
	margin: 30px 0;
	font-style: italic;
  }

  .error-contact {
	color: #FF0000;
  }

  .success-contact {
	color: #4bb41d;
  }

  address {

	//max-width: 750px;
	margin: 0 auto;

	.titre {
	  font-weight: bold;
	  font-size: 1rem;
	  margin-bottom: 10px;

	  span {
		font-weight: 300;
		display: block;
	  }
	}

	.info {
	  display: flex;
	  margin-bottom: 5px;

	  .icon {
		flex: 0 0 30px;

		svg {
		  fill: $primary;
		  width: 20px;
		  height: auto;
		}
	  }

	  a {
		text-decoration: none;
		font-size: 1rem;
		color: #000;
	  }
	}
  }
}
