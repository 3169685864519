@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');

@import "swiper/swiper";
@import "swiper/modules/navigation/navigation";
@import "swiper/modules/effect-fade/effect-fade";

@import "variables";
@import "bootstrap";
@import "components/commun";

@import "components/header";
@import "components/footer";
@import "components/popup";
@import "home";
@import "mentions";
