footer {
  background: $primary-dark;
  color: #FFF;
  font-size: 0.7rem;
  padding: 30px 0 80px 0;

  a {
	color: #FFF;
	text-decoration: none;

	&:hover {
	  color: #FFF;
	  font-weight: bold;
	}

	svg {
	  fill: #FFF;
	  height: auto;
	}

	&.link-group-pi {
	  svg {
		width: 150px;
	  }
	}

	&.link-linkedin {
	  svg {
		width: 30px;
	  }

	}
  }

}