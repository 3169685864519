.mentions-legales {

  h1 {
	color: $primary;
	font-weight: bold;
	font-size: 1.6rem;
	padding-top: 50px;
	margin-bottom: 25px;
	@include media-breakpoint-up(md) {
	  font-size: 1.8rem;
	}


  }

  h2 {
	border-left: 1px solid $primary;
	font-size: 1.1rem;
	line-height: 1.1rem;
	font-weight: bold;
	margin: 15px 0 10px 0;
	padding: 0 0 0 9px;
	@include media-breakpoint-up(md) {
	  font-size: 1.3rem;
	  line-height: 1.3rem;
	}

  }

  h3 {
	font-size: 1.1rem;
	font-weight: bold;
	margin: 0;
  }

  p {
	margin-bottom: 30px;
  }

  a {

  }
}