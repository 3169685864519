/******
Size font
********/
$font-family-sans-serif: 'Work Sans', sans-serif;
$container-padding-x: 1.5rem;

$primary-dark: #1F2C6C;
$primary: #29388C;
$primary-light: #00B6ED;
$gray: #F8F8F8;


@mixin media-landscape() {
  @media screen and (orientation: landscape) and (min-width: 768px) {
	@content;
  }
}

